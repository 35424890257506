import EventEmitter from "events";
import { XMPPEvents } from "./service/xmpp/XMPPEvents";
import * as ChatroomEvents from "./JitsiChatroomEvents";
import JitsiParticipant from "./JitsiParticipant";
import { Strophe } from "strophe.js";


/**
 * Creates a standalone XMPP based Chatroom without invoking jitsiConference
 *
 * @param roomName Name of the chatroom
 * @param option Confrence Connection Options
 * @param connection Xmmp connection object
 */

export default function JitsiChatroom(roomName, nickName, options, connection) {
    this.participants = {};

    if (!roomName || roomName.toLowerCase() !== roomName) {
        const errmsg =
            "Invalid conference name (no conference name passed or it " +
            "contains invalid characters like capital letters)!";

        logger.error(errmsg);
        throw new Error(errmsg);
    }

    this.options = options;
    this.eventEmitter = new EventEmitter();
    this.nickName = nickName;
    this.roomName = roomName;
    this._init(options, connection);
}

JitsiChatroom.prototype.constructor = JitsiChatroom;

// for authenticated users
// todo: implement after auth module
JitsiChatroom.authManager = function (jid, User) {};

JitsiChatroom.prototype._init = function (options, connection) {
    if (connection) {
        this.connection = connection;
        this.xmpp = this.connection.xmpp;
        // this.eventManager.setupXMPPListeners();
    }

    this.room = this.xmpp.createRoom(this.roomName, options);
    //this.room.join(); // todo: add support for password protected rooms

    // adding nickname
    this.room.addToPresence("nick", {
        attributes: { xmlns: "http://jabber.org/protocol/nick" },
        value: this.nickName,
    });
    this.room.sendPresence();

    // adding event listeners
    this.room.on(XMPPEvents.MESSAGE_RECEIVED, (jid, txt, myJid, ts) => {
        if (ts == undefined) {
            ts = new Date().toISOString().split(".")[0] + "Z";
        }
        else{
            return;
        }
        txt.timestamp = ts;
        this.eventEmitter.emit(
            ChatroomEvents.MESSAGE_RECEIVED,
            jid,
            txt,
            myJid
        );
    });

    this.xmpp.addListener(
        XMPPEvents.PRIVATE_MESSAGE_RECEIVED,
        (message) => {
            console.log("LOG EVENT");        
            this.eventEmitter.emit(
                ChatroomEvents.PRIVATE_MESSAGE_RECEIVED,
                message
            );
        }
    );

    this.room.on(
        XMPPEvents.MUC_MEMBER_JOINED,
        (
            from,
            nick,
            role,
            isHidden,
            statsID,
            status,
            identity,
            botType,
            jid,
            features,
            isReplaceParticipant
        ) => {
            const id = Strophe.getResourceFromJid(from);

            const participant = new JitsiParticipant(
                jid,
                this,
                nick,
                isHidden,
                statsID,
                status,
                identity
            );
            participant.setConnectionJid(jid);
            participant.setRole(role);
            participant.setBotType(botType);
            participant.setFeatures(features);
            participant.setIsReplacing(isReplaceParticipant);

            this.participants[id] = participant;

            this.eventEmitter.emit(
                ChatroomEvents.MUC_MEMBER_JOINED,
                this.participants
            );
        }
    );

    this.room.on(XMPPEvents.MUC_MEMBER_LEFT, (from) => {
        const id = Strophe.getResourceFromJid(from);

        const participant = this.participants[id];

        if (participant) {
            delete this.participants[id];
            this.eventEmitter.emit(ChatroomEvents.MUC_MEMBER_LEFT, participant);
        }
    });
};

JitsiChatroom.prototype.sendMessage = function (
    message,
    stanzaType,
    activity,
    activity_id,
    isPin,
    Pname,
    isdeleted,
    elementName = "body"
) {
    if (this.room) {
        this.room.sendMessage(
            message,
            stanzaType,
            activity,
            activity_id,
            isPin,
            Pname,
            isdeleted,
            elementName,
            this.nickName
        );
    }
};

JitsiChatroom.prototype.getParticipants = function () {
    return Object.values(this.participants);
};

JitsiChatroom.prototype.getArchivedMessages = function (start, end) {
    this.room.getArchivedMessages(start, end);
};

JitsiChatroom.prototype.getArchivedMessages2 = function (beforeId) {
    this.room.getArchivedMessages2(beforeId);
};

JitsiChatroom.prototype.on = function (eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};

JitsiChatroom.prototype.sendPrivateTextMessage = function (
    id,
    message,
    elementName = "body"
) {
    if (this.room) {
        console.log("Room", this.room);
        this.room.sendPrivateMessage(id, message, elementName);
    }
};

/* eslint-disable max-params */

/**
 * Notifies this JitsiConference that a new member has joined its chat room.
 *
 * FIXME This should NOT be exposed!
 *
 * @param jid the jid of the participant in the MUC
 * @param nick the display name of the participant
 * @param role the role of the participant in the MUC
 * @param isHidden indicates if this is a hidden participant (system
 * participant for example a recorder).
 * @param statsID the participant statsID (optional)
 * @param status the initial status if any
 * @param identity the member identity, if any
 * @param botType the member botType, if any
 * @param fullJid the member full jid, if any
 * @param features the member botType, if any
 * @param isReplaceParticipant whether this join replaces a participant with
 * the same jwt.
 */
JitsiChatroom.prototype.onMemberJoined = function (
    jid,
    nick,
    role,
    isHidden,
    statsID,
    status,
    identity,
    botType,
    fullJid,
    features,
    isReplaceParticipant
) {
    const id = Strophe.getResourceFromJid(jid);

    // if (id === 'focus' || this.myUserId() === id) {
    //     return;
    // }
    const participant = new JitsiParticipant(
        jid,
        this,
        nick,
        isHidden,
        statsID,
        status,
        identity
    );

    participant.setConnectionJid(fullJid);
    participant.setRole(role);
    participant.setBotType(botType);
    participant.setFeatures(features);
    participant.setIsReplacing(isReplaceParticipant);

    this.participants[id] = participant;
    // this.eventEmitter.emit(
    //     JitsiConferenceEvents.USER_JOINED,
    //     id,
    //     participant);

    // this._updateFeatures(participant);

    // // maybeStart only if we had finished joining as then we will have information for the number of participants
    // if (this.isJoined()) {
    //     this._maybeStartOrStopP2P();
    // }

    // this._maybeSetSITimeout();
};

/**
 * Receives notifications from other participants about commands / custom events
 * (sent by sendCommand or sendCommandOnce methods).
 * @param command {String} the name of the command
 * @param handler {Function} handler for the command
 */
JitsiChatroom.prototype.addCommandListener = function(command, handler) {
    if (this.room) {
        this.room.addPresenceListener(command, handler);
    }
};


/**
  * Removes command  listener
  * @param command {String} the name of the command
  * @param handler {Function} handler to remove for the command
  */
JitsiChatroom.prototype.removeCommandListener = function(command, handler) {
    if (this.room) {
        this.room.removePresenceListener(command, handler);
    }
};


/**
 * Send presence command.
 * @param name {String} the name of the command.
 * @param values {Object} with keys and values that will be sent.
 **/
JitsiChatroom.prototype.sendCommand = function(name, values) {
    if (this.room) {
        this.room.addOrReplaceInPresence(name, values) && this.room.sendPresence();
    } else {
        logger.warn('Not sending a command, room not initialized.');
    }
};

/**
 * Send presence command one time.
 * @param name {String} the name of the command.
 * @param values {Object} with keys and values that will be sent.
 **/
JitsiChatroom.prototype.sendCommandOnce = function(name, values) {
    this.sendCommand(name, values);
    this.removeCommand(name);
};

/**
 * Removes presence command.
 * @param name {String} the name of the command.
 **/
JitsiChatroom.prototype.removeCommand = function(name) {
    if (this.room) {
        this.room.removeFromPresence(name);
    }
};


JitsiChatroom.prototype.getMessageObject=function(msg){
    let txt = msg.match(/<body>(.*?)<\/body>/);
    const decodedStr = txt[1]
    .replace(/&quot;/g, '"')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');

// Parse the JSON string
let jsonObj;
try {
    jsonObj = JSON.parse(decodedStr);
} catch (error) {
    console.error('JSON parsing error:', error);
}
    //console.log(obj);
    return jsonObj;
}