import EventEmitter from 'events';
import {XMPPEvents} from './service/xmpp/XMPPEvents';
import * as ChatroomEvents from './JitsiChatroomEvents';
/**
 * Creates a standalone XMPP based Chatroom without invoking jitsiConference
 * 
 * @param roomName Name of the chatroom
 * @param option Confrence Connection Options
 * @param connection Xmmp connection object
 */


export default function JitsiChatroom(roomName,nickName,options,connection) {
    if (!roomName || roomName.toLowerCase() !== roomName) {
        const errmsg
            = 'Invalid conference name (no conference name passed or it '
                + 'contains invalid characters like capital letters)!';

        logger.error(errmsg);
        throw new Error(errmsg);
    }

    this.options = options;
    this.eventEmitter = new EventEmitter();
    this.nickName = nickName;
    this.roomName = roomName;
    this._init(options,connection);

}

JitsiChatroom.prototype.constructor = JitsiChatroom;

// for authenticated users
// todo: implement after auth module
JitsiChatroom.authManager = function (jid, User) { }

JitsiChatroom.prototype._init = function (options,connection) {
    if (connection)
    {
        this.connection = connection;
        this.xmpp = this.connection.xmpp;
        // this.eventManager.setupXMPPListeners();
    }
    
    this.room = this.xmpp.createRoom(this.roomName, options)
    
    this.room.join(); // todo: add support for password protected rooms
     
    // adding nickname
    this.room.addToPresence('nick', {
        attributes: { xmlns: 'http://jabber.org/protocol/nick' },
        value: this.nickName
    });
    this.room.sendPresence();
    
    // adding event listeners
    this.room.on(XMPPEvents.MESSAGE_RECEIVED, (jid, txt, myJid, ts)=>{
        if (ts == undefined) {
            ts = new Date().toISOString().split('.')[0] + "Z";
        }
        txt.timestamp = ts;
        this.eventEmitter.emit(ChatroomEvents.MESSAGE_RECEIVED, jid, txt, myJid)

    })
}

JitsiChatroom.prototype.sendMessage = function(message,stanzaType,activity,activity_id,isPin,isdeleted,elementName = "body")
{
    if (this.room) {
        this.room.sendMessage(message,stanzaType,activity,activity_id,isPin,isdeleted,elementName,this.nickName)
    }
}

JitsiChatroom.prototype.getArchivedMessages = function(start, end) {
    this.room.getArchivedMessages(start,end)
};
 
JitsiChatroom.prototype.on = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};
