/**
 * Events for chatroom
 */

 export enum JitsiChatroomEvents{

    MESSAGE_RECEIVED = 'chatroom.message_received'
    
 }

export const MESSAGE_RECEIVED = JitsiChatroomEvents.MESSAGE_RECEIVED;