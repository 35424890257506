/**
 * Events for chatroom
 */

/**
 * Events for chatroom
 */

export enum JitsiChatroomEvents{

    MESSAGE_RECEIVED = 'chatroom.message_received',
    MUC_MEMBER_JOINED = 'chatroom.muc_member_joined',
    MUC_MEMBER_LEFT = 'chatroom.muc_member_left',
    PRIVATE_MESSAGE_RECEIVED = 'chatroom.private_message_received'

    
 }

export const MESSAGE_RECEIVED = JitsiChatroomEvents.MESSAGE_RECEIVED;
export const MUC_MEMBER_JOINED = JitsiChatroomEvents.MUC_MEMBER_JOINED;
export const  MUC_MEMBER_LEFT = JitsiChatroomEvents. MUC_MEMBER_LEFT;
export const   PRIVATE_MESSAGE_RECEIVED = JitsiChatroomEvents.PRIVATE_MESSAGE_RECEIVED;
